import React, { useState } from 'react';
import './App.css'; // Assuming the styles are in a separate file

const ContactUs = () => {
  const [isFixed, setIsFixed] = useState(false); // Track if the section should be fixed
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [status, setStatus] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setStatus('Your message has been sent!');
  };

  return (
    <div className="contact-us-container">
      <div className="form-container">
        
        <h2>Contact Us</h2>
        <form className="contact-form" onSubmit={handleSubmit}>
          <div>
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <label htmlFor="message">Message:</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleInputChange}
              required
            />
          </div>
          <button type="submit">Send Message</button>
        </form>
        {status && <p>{status}</p>}
      </div>
      <div className="info-container">
        <h2>Reach Us</h2>
        <ul>
          <li>
            <strong>Location:</strong> 215 Nila, Technopark, Trivandrum, Kerala, India, 695581
          </li>
          <li>
            <strong>Email:</strong>{' '}
            <a href="mailto:support@sparklingapps.com">support@sparklingapps.com</a>
          </li>
          <li>
            <strong>Website:</strong>{' '}
            <a href="https://www.sparklingapps.com" target="_blank" rel="noopener noreferrer">www.sparklingapps.com</a>
          </li>
          
        </ul>
      </div>
      </div>
    
  );
};

export default ContactUs;
