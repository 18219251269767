import { initializeApp } from 'firebase/app';
import { getDatabase, ref, get } from 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyAAcdHYeQNwHdEk3UHDZHsueIOdEWHl-tA",
  authDomain: "admob-app-id-1676370691.firebaseapp.com",
  databaseURL: "https://admob-app-id-1676370691.firebaseio.com",
  projectId: "admob-app-id-1676370691",
  storageBucket: "admob-app-id-1676370691.appspot.com",
  messagingSenderId: "825330484706",
  appId: "1:825330484706:web:a18eba2ff2bea794c3a5bb",
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export { database, ref, get };