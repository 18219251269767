import React, { useState, useEffect } from "react";
import logo from './assets/123.png'; // Assuming your logo image path
import './App.css';
import './index.css';
import Section8 from './Section8';
import NewsSection from './NewsSection';
import { database, ref, get } from './firebase'; // Firebase import
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ContactUs from "./ContactUs";

function App() {
  const [isFixed, setIsFixed] = useState(false); // Track if the section should be fixed
  const [showLogoInNav, setShowLogoInNav] = useState(false); // Track if logo should appear in Nav bar
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to control the hamburger menu
  const [newsData, setNewsData] = useState([]); // State to store news data

  // Scroll effect to detect when the scroll section becomes fully visible
  useEffect(() => {
    const handleScroll = () => {
      const scrollSection = document.getElementById("scrollSection");
      const heroSection = document.getElementById("home");

      if (scrollSection) {
        const sectionRect = scrollSection.getBoundingClientRect();

        // Check if the entire scroll section is visible in the viewport
        if (sectionRect.top >= 0 && sectionRect.bottom <= window.innerHeight) {
          setIsFixed(true); // Fix the section when fully visible
        } else {
          setIsFixed(false); // Reset when it's not fully visible
        }
      }

      if (heroSection) {
        const heroBottom = heroSection.getBoundingClientRect().bottom;

        // Show logo in Nav bar if Hero section is scrolled out of view
        if (heroBottom <= 0) {
          setShowLogoInNav(true);
        } else {
          setShowLogoInNav(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup to avoid memory leaks
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const fetchNewsData = async () => {
      const newsRef = ref(database, 'news'); // Adjust this path as needed
      const snapshot = await get(newsRef);
      if (snapshot.exists()) {
        setNewsData(Object.values(snapshot.val()));
      } else {
        console.log("No news data found.");
      }
    };

    fetchNewsData();
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <div className="App">
      {/* Header Section */}
      <header className={`App-header ${showLogoInNav ? "show-logo" : ""}`}>
        <nav className="Nav-bar">
          {/* Left Side Logo */}
          <div className={`Nav-logo ${showLogoInNav ? "show-logo" : ""}`}>
            <img src={logo} alt="Logo" className="Nav-logo-img" />
          </div>

          {/* Desktop Navbar Items */}
          <div className="desktop-nav">
            <ul className="desktop-menu">
              <li><a href="#home">Home</a></li>
              <li><a href="#how-to-use">How To Use</a></li>
              <li><a href="#section-8">User Reviews</a></li>
              <li><a href="#section-7">News</a></li>
              <li><a href="#section-9">Contact Us</a></li>
            </ul>
          </div>

          {/* Only show hamburger on mobile and tablet devices */}
          <button className={`hamburger ${showLogoInNav ? "" : "hide"}`} onClick={toggleMenu} aria-label="Toggle menu">
            <span className="hamburger-line"></span>
            <span className="hamburger-line"></span>
            <span className="hamburger-line"></span>
          </button>
        </nav>

        {/* Left Pane Navigation for Mobile */}
        <aside className={`left-pane ${isMenuOpen ? "open" : ""}`}>
          <button className="close-button" onClick={closeMenu} aria-label="Close menu">&times;</button>
          <ul className="menu-items">
            <li><a href="#home" onClick={closeMenu}>Home</a></li>
            <li><a href="#how-to-use" onClick={closeMenu}>How To Use</a></li>
            <li><a href="#section-8" onClick={closeMenu}>User Reviews</a></li>
            <li><a href="#section-7" onClick={closeMenu}>News</a></li>
            <li><a href="#section-9" onClick={closeMenu}>Contact Us</a></li>
          </ul>
        </aside>
      </header>
      {/* Hero Section */}
      <section className="Hero-section" id="home">
        <div className="Hero-content">
          <img src={logo} alt="PiccyBot Logo" className="Hero-logo" />
          <div className="Hero-text">
            <h1>PiccyBot</h1>
            <p className="catchy-phrase">Revolutionizing Visual Accessibility</p>
          </div>


          <div className="Hero-download-box">
            <a
              href="https://apps.apple.com/us/app/id1614981710"
              target="_blank"
              rel="noopener noreferrer"
              className="App-link"
            >
              <img
                src="/images/appstore.jpg"
                alt="Download on the App Store"
                className="HeroDownloadImg"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.sparklingapps.piccybot"
              target="_blank"
              rel="noopener noreferrer"
              className="App-link"
            >
              <img
                src="/images/googleplay.jpg"
                alt="Get it on Google Play"
                className="HeroDownloadImg"
              />
            </a>
          </div>
        </div>
      </section>

      <section className="Helping-blind-section" id="helping-blind">
        <div className="Helping-blind-content">

          <div className="Helping-blind-text">
            <h1>Helping the Blind</h1>

            <p className="Helping-blind-paragraph">
              PiccyBot is designed to bridge the gap between the visually impaired and the world of visual memories. With advanced AI, it transforms photos and videos into vivid, detailed descriptions, helping users connect with moments they might otherwise miss.
            </p>

            <p className="Helping-blind-paragraph">
              By offering an enriched experience through descriptive narratives, PiccyBot allows blind and visually impaired individuals to understand and cherish their memories. Whether it’s a family photo or a serene landscape, every image comes to life in words.
            </p>

            <p className="Helping-blind-paragraph">
              PiccyBot is more than an app; it’s a companion that empowers users to embrace and enjoy the beauty of everyday life. With PiccyBot, accessibility meets innovation to create unforgettable moments.
            </p>
          </div>

        </div>
      </section>

      <section className="HowToUse-section" id="how-to-use">
        <h2 className="HowToUse-heading">How to Use PiccyBot</h2>
        <p className="HowToUse-intro">
          Discover how PiccyBot empowers you to capture and relive moments effortlessly. Just a few simple steps stand between you and a vivid description of your cherished memories.
        </p>
        <div className="HowToUse-content">
          {/* Steps on the left */}
          <div className="HowToUse-steps">
            <div className="HowToUse-step">
              <span className="Step-number">1</span>
              <div className="Step-content">
                <h3>Download and Launch the App</h3>
                <p>
                  Start by downloading PiccyBot from the
                  <a href="https://apps.apple.com/us/app/id1614981710" target="_blank" rel="noopener noreferrer"> App Store</a>
                  or
                  <a href="https://play.google.com/store/apps/details?id=com.sparklingapps.piccybot" target="_blank" rel="noopener noreferrer"> Google Play</a>. Open the app to begin your journey.
                </p>
              </div>
            </div>
            <div className="HowToUse-step">
              <span className="Step-number">2</span>
              <div className="Step-content">
                <h3>Capture or Select Media</h3>
                <p>
                  Tap the **Record** button to start capturing a live video or image. If you already have media in your gallery, select it directly from the app. Once you're done recording, the app automatically stops and moves to the next step.
                </p>
              </div>
            </div>
            <div className="HowToUse-step">
              <span className="Step-number">3</span>
              <div className="Step-content">
                <h3>Instant AI Processing</h3>
                <p>
                  Once you stop recording or select media, PiccyBot's AI immediately processes it. You don’t need to do a thing! The app generates a detailed, vivid description of your image or video within seconds.
                </p>
              </div>
            </div>
            <div className="HowToUse-step">
              <span className="Step-number">4</span>
              <div className="Step-content">
                <h3>Receive and Share Descriptions</h3>
                <p>
                  Read or listen to the detailed description of your media. Share the description with friends or save it for later. Relive and share your memories with ease.
                </p>
              </div>
            </div>
          </div>

          {/* GIF on the right */}
          <div className="HowToUse-gif">
            <img
              src="/images/howtouse.gif"
              alt="How to Use PiccyBot: Overview"
              className="Steps-gif"
            />
          </div>
        </div>
      </section>

      <section id="section-6" class="scroll-section">
        <div class="content">
          <p class="section-text">
            Effortlessly share your reels, videos, and photos from top social media apps to our platform.
          </p>
          <img src="/images/share.gif" alt="PiccyBot Share Animation" />
        </div>
      </section>

      <section id="section-3" class="scroll-section">
        <div class="content">
          <h2>
            Unlock the Power of 10+ Cutting-Edge AI Models – Delivering Stunning Descriptions That Bring Images and Videos to Life.
          </h2>
          <img src="/images/5.gif" alt="PiccyBot Image" />
        </div>
      </section>

      <section id="section-2" class="scroll-section">
        <div class="content">
          <h2>
            Redefine Your Experience – Effortless Control, Personalized Interaction, Unlimited Possibilities.
          </h2>
          <img src="/images/2.png" alt="PiccyBot Image" />
        </div>
      </section>



      <section id="section-7" className={`scroll-section ${isFixed ? 'relative' : ''}`}>
        <NewsSection newsData={newsData} />
      </section>

      <Section8 />

      <section id="section-9" className={`scroll-section ${isFixed ? 'fixed' : ''}`}>
        <ContactUs /> {/* Your ContactUs component here */}
      </section>


      <footer>
        <p>&copy; 2024 Sparkling Apps. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;

